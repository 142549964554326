
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・AMG】走行距離と年式の関係とは？中古車購入時のチェックポイントを解説
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            走行距離に関わらず安全で快適な中古車を選ぶためには、年式と走行距離の正しい関係を知っておくことが重要です。AMGモデルなど中古車購入時の選び方や注意すべきポイントを把握しておきましょう。
            <div class="section-title">
              <span >低走行だけで選ばない！中古車購入時のチェックポイント</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >中古車を選ぶ上で注意すべきポイントはいくつか存在しますが、中でも「走行距離」と「年式」は必ずチェックしておかなければなりません。</p>
                    <h3 class="heading-number" data-number="◆">中古車の安全走行距離</h3>  
                    <p >「車の寿命は10年・10万km」と聞いたことがあるかもしれませんが、現在は技術の向上により車の性能も高くなっていることから、しっかりメンテナンスを行えば40万kmは安全に走行可能です。</p>
                    <p >海外でも、特にメンテナンスすることなく日本車が20万km以上走行し続けています。必要なメンテナンスを怠らなければ、15～20万kmは問題なく走行できるでしょう。</p>
                    <h3 class="heading-number" data-number="◆">年式と走行距離の関係とは？</h3>  
                    <p >走行距離においては低走行だからよいというわけではありません。また、走行距離と合わせて確認しておきたいのが中古車の年式です。</p>
                    <p >1年間のおおよその走行距離は8000～1万kmといわれており、年式に対して極端に走行距離が少ない中古車の場合、放置やメンテナンス不足という理由が潜んでいるかもしれません。</p>
                    <p >また、保管状況によっては車の塗装・内部の消耗品なども傷んでいる可能性がありますので、走行距離だけでは中古車の良し悪しは判断できないのです。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img src="@/assets/img/column-gtr-amg/used-car-check-point.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">ベストな走行距離</h3>  
              <p >中古車を選ぶ上でベストな走行距離は、重視する項目によって変わります。</p>
              <ul>
                <li>車両のきれいさを重視：走行距離3～5万km</li>
                <li>価格と状態のバランスを重視：走行距離6～10万km</li>
                <li>激安で中古車を入手したい：走行距離10～12万km</li>
              </ul>
              <p >走行距離が3万～5万kmの場合は、比較的経年劣化が少ない状態の車両が多い傾向にあります。走行距離が5万kmを超えたあたりから価格が下がるため、走行距離6～10万kmの中古車は価格と状態のバランスがちょうどよく、安全に安く乗れる車を希望の方におすすめです。</p>
              <h3 class="heading-number" data-number="◆">外車の中でも長く乗れるベンツ</h3>  
              <p >日本車に比べて故障しやすいイメージのある外車ですが、数ある海外メーカーの中でもメルセデスベンツは長く乗り続けられるといわれています。</p>
              <p >メルセデスベンツは1ヶ所の部品が故障した場合、対象の部品のみを修理・交換する仕組みを導入していますので、購入時と限りなく近い状態に復元しやすく、いつまでも新車に近い車を運転できます。</p>
              <p >メルセデスベンツの中古車をお探しの方へ、AMGモデルをおすすめします。細かなチューニング・カスタムによって、サーキットでの走行にも耐えられるよう設計されており、高パフォーマンスな走りを長く楽しめます。</p>
              <p class="u-m0 u-mt20">
                <b style="color: #c49a00;">【メルセデスAMG人気モデル】</b></p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG A45</h4>
              <p >コンパクトな車に分類されるAクラスのAMGモデルです。コンフォート、スポーツ、レースなど、4つのモードで走行できるため、アクティブ走行が好きな方に向いています。</p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG E63</h4>
              <p >Eクラスに分類されており、ベンツのクラスの中では高いグレードに位置しています。特に強力な最高出力600PSオーバーのターボエンジンを搭載していますので、とても力強い走りが可能です。</p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>メルセデスAMG GT 4ドアクーペ</h4>
              <p >世界トップ水準の運動性能を誇り、ハッチゲートを開けると広い荷室が現れる機能性も備えています。パフォーマンスやデザイン性を求めながらも、日常生活での使い勝手も重視したい方におすすめです。</p>
              <div class="c-btn">
                <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >長く安全に走れる中古車をお探しの方はcar shop MIXにご相談を</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >中古車を選ぶ上で重要なのは、年式と走行距離の関係によって車両の状態が違うことを認識しておくことです。走行距離だけを確認するのではなく、年式と走行距離の両方を確認し、車の状態をしっかりと見極める必要があります。</p>
                    <p >低走行でも年式が古い場合、放置やメンテナンス不足により塗装や内部部品などの経年劣化が進んでいる可能性があります。また、中古車を選ぶ上でのベストな走行距離は、重視したい項目によって異なりますので、希望の車両の状態や価格に合わせた走行距離の中古車を選ぶのがおすすめです。</p>
                    <p >中古車でも長く安全に走れる車をお探しの方は、car shop MIXにお任せください。低走車や25年規制が解除されるような車を集めることを得意としております。</p>
                    <p >在庫一覧に希望の車両がない場合は、オークションを代行してお探しすることも可能ですので、レアな車種やマニュアル車をお探しの方は、お気軽にご相談ください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="赤色のスポーツカー" src="@/assets/img/column-gtr-amg/red-sports-car.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'メルセデスAMGの人気モデルをお探しなら中古車販売のcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv07.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
