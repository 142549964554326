
<template>
    <div>
      <column-list :title="columnListTitle" :linkList="linkList"/>
      <column-footer :title="title"/>
    </div>
  </template>
  
  
  <script>
  import ColumnFooter from "../Footer.vue";
  import ColumnList from "../Columlist.vue";

  export default {
    components: {
      ColumnFooter,
      ColumnList
    },
    props: {
      title: {
        type: String,
        default: '人気のGT-RやメルセデスAMGなどの中古車をお探しならcar shop MIXへ'
      },
      columnListTitle: {
        type: String,
        default: '中古車選びや人気モデルのGT-R・AMGに関するコラム'
      }
    },
    data: () => ({
      linkList:[],
    }),
    created() {
      this.linkList = this.$_menu(this.commonSetting.columMenuListGtrAmg);
    },
  }
  </script>
  
  
  
  <style scoped lang="scss">
  </style>
  